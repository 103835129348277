import CosmicModule from '@finn/auto-ui/ui-modules/CosmicModule';
import { SpacingWrapper } from '@finn/platform-modules';
import React from 'react';

import { CosmicModuleType, PDPModules } from '~/types/cosmicModules';

export const FrequentlyAskedQuestions: React.FC<{ pdpModules: PDPModules }> = ({
  pdpModules,
}) => {
  const frequentlyAskedQuestionsModule = pdpModules.metadata.modules.find(
    (module) => module.type === CosmicModuleType.WEB_MODULE_ACCORDION
  );

  if (!frequentlyAskedQuestionsModule) {
    return null;
  }

  return (
    <SpacingWrapper>
      <CosmicModule data={frequentlyAskedQuestionsModule} />
    </SpacingWrapper>
  );
};
