import { useConfigureStore } from '@finn/auto-ui/components/ConfigurePage';
import { Features, useIsABVariant, useIsCVariant } from '@finn/ua-featureflags';
import { GenericVehicleDetails, VehicleContext } from '@finn/ua-vehicle';
import { useCalculatePrice } from '@finn/ui-modules';
import { useContext } from 'react';

export function useMonthlySubscriptionCost(
  initVehicle?: GenericVehicleDetails
) {
  const { vehicle } = useContext(VehicleContext);
  const { term, kilometerPackage, downPayment, downPaymentAmount } =
    useConfigureStore();

  const isBVariant = useIsABVariant(Features.ExpDownPaymentOptions);
  const isCVariant = useIsCVariant(Features.ExpDownPaymentOptions);

  const isExperimentDownPayment = isBVariant || isCVariant;

  const isFakedoorDownpayment = useIsABVariant(Features.ExpFakedoorDownpayment);

  const priceParams = {
    term,
    kilometerPackage,
    downPaymentAmount,
    isFakedoorDownpayment,
    isExperimentDownPayment,
    isDownPayment: downPayment,
    vehicle: vehicle || initVehicle,
  };

  const monthlyCost = useCalculatePrice(priceParams);

  const total = monthlyCost;

  return { monthlyCost, total };
}
