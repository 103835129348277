import throttle from 'lodash/throttle';
import { MutableRefObject, useRef, useState } from 'react';

import useIsomorphicLayoutEffect from '~/hooks/useLayoutEffectSSR';

const TOP_OFFSET = 10;

const useIsScrolledUp = <T extends Element>(
  retain?: boolean
): [MutableRefObject<T | null>, boolean] => {
  const targetRef = useRef<T>(null);
  const [isScrolledUp, setIsScrolledUp] = useState(false);

  useIsomorphicLayoutEffect(() => {
    const setScrolledUpCallback = throttle(() => {
      const boundingBox = targetRef?.current?.getBoundingClientRect?.();
      if (boundingBox?.y < TOP_OFFSET) {
        setIsScrolledUp(true);
      } else if (!retain) {
        setIsScrolledUp(false);
      }
    }, 500);

    setScrolledUpCallback();
    window.addEventListener('resize', setScrolledUpCallback, {
      passive: true,
    });
    window.addEventListener('scroll', setScrolledUpCallback, {
      passive: true,
    });

    return () => {
      window.removeEventListener('resize', setScrolledUpCallback);
      window.removeEventListener('scroll', setScrolledUpCallback);
    };
  }, [retain]);

  return [targetRef, isScrolledUp];
};

export default useIsScrolledUp;
