import { useIntl } from 'react-intl';

import { AlertMessage } from '~/components/Alerts';

type Props = {
  isCartNotAvailable: boolean;
  isTermNotAvailable: boolean;
};

export const PageAlerts = ({
  isCartNotAvailable,
  isTermNotAvailable,
}: Props) => {
  const i18n = useIntl();

  return (
    <>
      {isCartNotAvailable && (
        <AlertMessage
          message={i18n.formatMessage({
            id: 'general.cartNotAvailableMessage',
          })}
        />
      )}
      {isTermNotAvailable && (
        <AlertMessage
          message={i18n.formatMessage({
            id: 'general.checkoutTermNotAvailableMessage',
          })}
        />
      )}
    </>
  );
};

export default PageAlerts;
