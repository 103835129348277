import { makeStyles, Theme } from '@material-ui/core';

export const useStyles = makeStyles((theme: Theme) => ({
  stickyBar: {
    position: 'fixed',
    zIndex: theme.customZIndices.pdpStickyBar,
    top: 0,
    left: 0,
    right: 0,
    height: theme.customStyles.stickyNav.height,
    boxShadow: '0px 0px 15px rgba(39, 43, 62, 0.08)',
    transition: 'transform 300ms ease-in-out',
    transform: 'translateY(-100%)',
  },
  stickyBarBottom: {
    top: 'auto',
    left: 0,
    right: 0,
    bottom: 0,
    transform: 'translateY(100%)',
  },
  stickyBarVisible: {
    transform: 'translateY(0)',
  },
  stickyTopContent: {
    display: 'flex',
    height: theme.customStyles.stickyNav.height,
    alignItems: 'center',
    maxWidth: '100%',
  },
  iconBox: {
    flex: `${theme.customStyles.stickyNav.iconWidth} 0 0`,
    display: 'none',
    [theme.breakpoints.up('md')]: {
      display: 'block',
    },
    '& img': {
      maxWidth: '100%',
    },
  },
  productBox: {
    flex: `${theme.customStyles.stickyNav.widthBasis} 1 0`,
    paddingRight: 8,
    display: 'none',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    [theme.breakpoints.up('md')]: {
      display: 'block',
    },
  },
  priceBox: {
    flex: 'auto 1 0',
    padding: theme.spacing(0, 2, 0, 0),
    [theme.breakpoints.up('md')]: {
      flexGrow: '0',
    },
    [theme.breakpoints.down('xs')]: {
      flex: '132px 1 1',
    },
  },
  flexEndLG: {
    justifyContent: 'flex-end',
    display: 'flex',
    alignItems: 'center',
    [theme.breakpoints.down('md')]: {
      justifyContent: 'flex-start',
    },
  },
  vehicleModelBox: {
    marginBottom: theme.spacing(1),
  },
  button: {
    [theme.breakpoints.down('sm')]: {
      width: '50%',
    },
  },
}));
