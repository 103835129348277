import { createMarkup } from '@finn/auto-ui/utils/html';
import Head from 'next/head';

type Props = {
  ldMarkup: string;
  ldFAQMarkup?: string;
};

export const PageSetupScripts = ({ ldMarkup, ldFAQMarkup }: Props) => (
  <Head>
    <script
      type="application/ld+json"
      dangerouslySetInnerHTML={createMarkup(ldMarkup)}
    />
    <script
      type="application/ld+json"
      dangerouslySetInnerHTML={createMarkup(ldFAQMarkup)}
    />
  </Head>
);

export default PageSetupScripts;
