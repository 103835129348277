import { GenericVehicleDetails } from '@finn/ua-vehicle';
import { makeStyles, Theme } from '@material-ui/core';
import cn from 'classnames';
import get from 'lodash/get';
import React from 'react';
// TODO design-system: refactor !important styles after migration to new typography APP-1443

const useStyles = makeStyles((theme: Theme) => ({
  wrapper: {
    display: 'inline-flex',
    maxWidth: '70%',
    textAlign: 'center',
    borderRadius: '2px',
    border: '1px solid',
    padding: theme.spacing(0.75, 0.75),
    [theme.breakpoints.up('md')]: {
      padding: theme.spacing(1, 1),
    },
  },
}));

interface IProps {
  vehicle: GenericVehicleDetails;
  className?: string;
}
const ProductLabel: React.FunctionComponent<IProps> = ({
  vehicle,
  className,
}) => {
  const label = get(vehicle, 'product_label.label');
  // Out if there is no label
  if (!label) {
    return null;
  }

  // eslint-disable-next-line react-hooks/rules-of-hooks
  const classes = useStyles();

  return (
    <span className={cn(classes.wrapper, '!border-pearl !bg-white', className)}>
      <p
        className={
          'body-12-medium overflow-hidden text-ellipsis whitespace-nowrap py-1'
        }
      >
        {label}
      </p>
    </span>
  );
};

export default ProductLabel;
