import { Breadcrumb } from '@finn/ua-auth';
import Head from 'next/head';
import React from 'react';

import { useCurrentLocale } from '~/hooks/useCurrentLocale';
import { buildBreadcrumbsJsonLdAsStr, createMarkup } from '~/utils/html';

interface IProps {
  breadcrumbs: Breadcrumb[];
}

const ProductBreadcrumbs: React.FunctionComponent<IProps> = ({
  breadcrumbs,
}) => {
  const { locale } = useCurrentLocale();
  const jsonldAsString = buildBreadcrumbsJsonLdAsStr(breadcrumbs, locale);

  return (
    <>
      <Head>
        <script
          type="application/ld+json"
          dangerouslySetInnerHTML={createMarkup(jsonldAsString)}
        />
      </Head>
    </>
  );
};

export default ProductBreadcrumbs;
