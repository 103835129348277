import { DownpaymentTooltipModal } from '@finn/auto-ui/components/ConfigurePage/DownPayment/DownpaymentTooltipModal';
import { Button } from '@finn/design-system/atoms/button';
import { ToggleGroup, ToggleItem } from '@finn/design-system/atoms/toggle';
import { Collapse, CollapseTypes } from '@finn/platform-modules';
import {
  Features,
  useDownPayment,
  useIsABVariant,
  useIsCVariant,
} from '@finn/ua-featureflags';
import { ModalContainer, ModalKey, useOpenModal } from '@finn/ua-modals';
import { InfoToolTip } from '@finn/ui-components';
import {
  calculateTotalPrice,
  getForBusinessCookie,
  interactionTrackingEvent,
  TrackingEventName,
} from '@finn/ui-modules';
import { cn, getFormattedPrice, trackEvent } from '@finn/ui-utils';
import { makeStyles, Theme } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';

import { removeGermanTax } from '~/utils/checkout';

import { useConfigureStore } from '../ConfigurePageStore';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    marginTop: theme.spacing(3),
  },
  options: {
    display: 'flex',
    columnGap: theme.spacing(1),
  },
  oneTimePriceRoot: {
    display: 'flex',
    justifyContent: 'space-between',
    transition: '0.3s height, 0.3s opacity',
    overflow: 'hidden',
    height: 0,
    opacity: 0,
    alignItems: 'center',
    '&.visible': {
      height: 48,
      opacity: 1,
    },
  },
}));

const onTooltipOpenChange = (opened: boolean) => {
  if (opened) {
    interactionTrackingEvent(TrackingEventName.TOOLTIP_HOVERED, {
      name: 'downpayment tooltip',
    });
  }
};

const onAccordionChange = (_, expanded: boolean) =>
  interactionTrackingEvent(TrackingEventName.ACCORDION_CLICKED, {
    location: 'downpayment-tooltip',
    expanded,
  });

export const DownPayment: React.FC = () => {
  const i18n = useIntl();
  const classes = useStyles();
  const { isDownPaymentEnabled } = useDownPayment();
  const [isForBusiness, setIsForBusiness] = useState(false);
  const openModal = useOpenModal();

  const isBVariantNoTogglePrices = useIsABVariant(
    Features.ExperimentPCPNoTogglePrices
  );
  const { setDownPayment, downPayment, vehicle, term } = useConfigureStore();
  const isExperimentDownPaymentDiscount = useIsABVariant(
    Features.ExpDownPaymentOptions
  );

  const isExpPCPDownpaymentTooltipB = useIsABVariant(
    Features.ExpPCPDownpaymentTooltip
  );
  const isExpPCPDownpaymentTooltipC = useIsCVariant(
    Features.ExpPCPDownpaymentTooltip
  );
  const isExpPCPDownpaymentTooltip =
    isExpPCPDownpaymentTooltipB || isExpPCPDownpaymentTooltipC;

  useEffect(() => {
    const isForBusinessCookie = getForBusinessCookie();
    setIsForBusiness(isForBusinessCookie);
  }, []);

  const handleModalOpen = () => {
    interactionTrackingEvent(TrackingEventName.MODAL_OPENED, {
      name: 'downpayment tooltip',
    });
    openModal(ModalKey.PCP_DOWNPAYMENT_TOOLTIP);
  };

  const downPaymentAmount = isForBusiness
    ? Math.round(
        removeGermanTax(vehicle?.downpayment_prices?.downpayment_fixed_amount)
      )
    : vehicle?.downpayment_prices?.downpayment_fixed_amount;

  const oneTimePrice = getFormattedPrice(i18n, downPaymentAmount);
  const price = calculateTotalPrice({ term, vehicle });
  const downpaymentPrice = calculateTotalPrice({
    term,
    vehicle,
    isDownPayment: true,
    downPaymentAmount,
    isExperimentDownPayment: isExperimentDownPaymentDiscount,
  });

  const downpaymentDiscount = price - downpaymentPrice;

  if (!vehicle) return null;

  if (!isDownPaymentEnabled) return null;

  const downpaymentToggleDescription = isExperimentDownPaymentDiscount
    ? null
    : i18n.formatMessage({
        id: 'pdp.downPayment.optionDescriptionWith',
      });
  const regularToggleDescription = isBVariantNoTogglePrices
    ? null
    : `+ ${getFormattedPrice(i18n, downpaymentDiscount, 0)}`;

  return (
    <>
      <div className={classes.root}>
        <div
          className={cn('my-2 flex', {
            'flex-row items-center gap-2': !isExpPCPDownpaymentTooltip,
            'flex-col': isExpPCPDownpaymentTooltipB,
            'items-center justify-between': isExpPCPDownpaymentTooltipC,
          })}
        >
          <h6 className="global-t6-semibold">
            {i18n.formatMessage({ id: 'pdp.downPayment.title' })}
          </h6>

          {isExpPCPDownpaymentTooltipB && (
            <Collapse
              index={0}
              onChange={onAccordionChange}
              title={
                <p className="body-14-semibold">
                  {i18n.formatMessage({
                    id: 'pdp.experiment.downpaymentTooltip.howDoesItWork',
                  })}
                </p>
              }
              type={CollapseTypes.BORDERLESS}
            >
              <p className="body-14-light">
                {i18n.formatMessage({
                  id: 'pdp.experiment.downpaymentTooltip.description',
                })}
              </p>
            </Collapse>
          )}

          {isExpPCPDownpaymentTooltipC && (
            <Button onClick={handleModalOpen} variant="action" size="md">
              {i18n.formatMessage({
                id: 'pdp.experiment.downpaymentTooltip.moreInfo',
              })}
            </Button>
          )}

          {!isExpPCPDownpaymentTooltip && (
            <InfoToolTip
              onOpenChange={onTooltipOpenChange}
              content={i18n.formatMessage({
                id: `${isExperimentDownPaymentDiscount ? 'pdp.downPayment.tooltipExperiment' : 'pdp.downPayment.tooltip'}`,
              })}
            />
          )}
        </div>

        <ToggleGroup
          className="mt-1"
          cols={2}
          defaultValue={downPayment === true ? 'downpayment' : 'regular'}
          value={downPayment === true ? 'downpayment' : 'regular'}
          onValueChange={(value) => {
            if (value) {
              const withDownpayment = value === 'downpayment';

              trackEvent(`DownPayment Selected`, {
                downPayment: withDownpayment,
              });
              setDownPayment(withDownpayment);
            }
          }}
        >
          {['downpayment', 'regular'].map((value) => (
            <ToggleItem
              key={value}
              variant="regular"
              title={
                value === 'downpayment'
                  ? i18n.formatMessage({
                      id: 'pdp.downPayment.optionTitleWith',
                    })
                  : i18n.formatMessage({
                      id: 'pdp.downPayment.optionTitleWithout',
                    })
              }
              value={value}
              description={
                value === 'downpayment'
                  ? downpaymentToggleDescription
                  : regularToggleDescription
              }
              withBadge={value === 'downpayment'}
            />
          ))}
        </ToggleGroup>
        <div
          className={`${classes.oneTimePriceRoot} ${downPayment ? 'visible' : ''}`}
        >
          <div className="body-14-semibold">
            {i18n.formatMessage({ id: 'pdp.downPayment.oneTimePrice' })}
          </div>
          <div className="body-14-regular">{oneTimePrice}</div>
        </div>
      </div>

      <ModalContainer
        modalKey={ModalKey.PCP_DOWNPAYMENT_TOOLTIP}
        ModalComponent={DownpaymentTooltipModal}
      />
    </>
  );
};
