import { cn } from '@finn/ui-utils';
import { Box, makeStyles, Theme } from '@material-ui/core';
import React, { useState } from 'react';

import RawHtml from '~/components/RawHtml';
import { PDPData } from '~/types/cosmicModules';

import PDPTracking from '../utils/PDPTracking';
import { PreLovedModal } from './PreLovedModal';

interface IProps {
  pdpData: PDPData;
}

const useStyles = makeStyles((theme: Theme) => ({
  prelovedBox: {
    padding: theme.spacing(2.25, 2.625, 2.25, 2),
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
}));

export const PreLovedInfo: React.FunctionComponent<IProps> = ({ pdpData }) => {
  const classes = useStyles();
  const [modalOpen, setModalOpen] = useState(false);

  const handleInfoClick = (event) => {
    PDPTracking.helpOptionClicked('preloved');
    setModalOpen(true);
    event?.stopPropagation?.();
  };

  const handleModalClose = (event) => {
    setModalOpen(false);
    event.stopPropagation();
  };

  const { description, link, modal_text, modal_title } =
    pdpData?.metadata.content.preloved || {};

  return (
    <Box className={cn(classes.prelovedBox, 'bg-snow')}>
      <span className="body-14-regular inline">
        <RawHtml html={description} />
        <a
          href="javascript:void(0)"
          className="link-14"
          onClick={handleInfoClick}
        >
          {link}
        </a>
      </span>
      <PreLovedModal
        open={modalOpen}
        onClose={(event) => handleModalClose(event)}
        data={{
          modal_title,
          modal_text,
        }}
      />
    </Box>
  );
};
