import { Hidden, makeStyles, Theme } from '@material-ui/core';
import cn from 'classnames';
import React from 'react';

import RawHtml from '~/components/RawHtml';
import { BaseCosmicObject } from '~/types/cosmicModules';
import { getPromoBannerHtml } from '~/utils/plp/plp';

const useStyles = makeStyles((theme: Theme) => ({
  wrapper: {
    marginBottom: theme.spacing(2),
    [theme.breakpoints.up('md')]: {
      marginBottom: theme.spacing(0),
    },
  },
  root: {
    minHeight: '10px',
    '& a': {
      borderBottom: 0,
    },
    [theme.breakpoints.up('sm')]: {
      minHeight: '10px',
    },
    [theme.breakpoints.up('md')]: {
      minHeight: '10px',
    },
  },
}));

interface IProps {
  pageData: BaseCosmicObject<any>;
  isPdp?: boolean;
  className?: string;
}

const PromoBanner: React.FunctionComponent<IProps> = ({
  pageData,
  isPdp,
  className,
}) => {
  const classes = useStyles();

  const bannerHtmlMobile = getPromoBannerHtml(pageData, isPdp, true);
  const bannerHtmlDesktop = getPromoBannerHtml(pageData, isPdp, false);

  if (!bannerHtmlMobile && !bannerHtmlDesktop) return null;

  return (
    <div className={classes.wrapper}>
      <Hidden mdUp implementation="css">
        <RawHtml
          html={bannerHtmlMobile || bannerHtmlDesktop}
          className={cn(className, classes.root)}
          useGlobalStyles
          fullWidthMedia
        />
      </Hidden>
      <Hidden smDown implementation="css">
        <RawHtml
          html={bannerHtmlDesktop || bannerHtmlMobile}
          className={cn(className, classes.root)}
          useGlobalStyles
          fullWidthMedia
        />
      </Hidden>
    </div>
  );
};

export default PromoBanner;
