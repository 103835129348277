import { Features, useIsABVariant } from '@finn/ua-featureflags';
import { getConfigLineLabel, isVehicleComingSoon } from '@finn/ua-vehicle';
import { useCurrentLocale } from '@finn/ui-utils';
import classnames from 'classnames';
import React, { useContext } from 'react';
import { useIntl } from 'react-intl';

import AppContext from '~/contexts/App';
import VehicleContext from '~/contexts/Vehicle';

import { useIsPDPCarInfoExp } from './utils/useIsPDPCarInfoExp';

type VehiclesModelProps = {
  drawerView?: boolean;
};

const VehiclesModel: React.FunctionComponent<VehiclesModelProps> = ({
  drawerView = false,
}) => {
  const isExpPDPCarInfo = useIsPDPCarInfoExp();

  const isExpPDPAvailabilityLabel = useIsABVariant(
    Features.ExpPDPAvailabilityLabel
  );
  const { vehicle } = useContext(VehicleContext);
  const { triggerDevModal } = useContext(AppContext);
  const configLineLabel = getConfigLineLabel(vehicle);
  const { isUSA } = useCurrentLocale();
  const isComingSoon = isVehicleComingSoon(vehicle);
  const i18n = useIntl();

  return (
    <div className="flex flex-col">
      <div
        className="flex items-center justify-between"
        onClick={triggerDevModal}
      >
        <h1
          className={classnames({
            'mobile-t3-semibold md:web-t3-semibold mb-2 mr-2 mt-2 md:mb-4 md:mt-4':
              !isExpPDPCarInfo,
            'mobile-t4-semibold md:web-t4-semibold mt-2':
              isExpPDPCarInfo && !drawerView,
            'global-t5-semibold': isExpPDPCarInfo && drawerView,
            '!mt-0': isExpPDPAvailabilityLabel,
          })}
        >
          <span className="inline-block">{vehicle.brand?.id}</span>{' '}
          <span className="inline-block">{vehicle.model}</span>
        </h1>
      </div>
      {isUSA && isComingSoon && (
        <p className="body-14-regular mb-1 mt-3">
          {i18n.formatMessage({ id: 'pdp.similar_config' })}
        </p>
      )}
      {(!isUSA || !isComingSoon) && (
        <p
          className={classnames({
            'body-14-regular mb-1 mt-2': !isExpPDPCarInfo,
            'mobile-t4-light md:web-t4-light mb-1 mt-5':
              isExpPDPCarInfo && !drawerView,
            'global-t5-light mt-3': isExpPDPCarInfo && drawerView,
          })}
        >
          {configLineLabel}
        </p>
      )}
    </div>
  );
};

export default VehiclesModel;
