import { Box, makeStyles, Theme } from '@material-ui/core';
import React, { useContext } from 'react';
import { useIntl } from 'react-intl';

import useKeyDataItems from '~/components/KeyData/hooks/useKeyDataItems';
import VehicleContext from '~/contexts/Vehicle';

import { useIsPDPCarInfoExp } from '../ProductDetails/utils/useIsPDPCarInfoExp';
import KeyDataList from './KeyDataList';

type StyleProps = {
  drawerView: boolean;
};

const useStyles = makeStyles((theme: Theme) => ({
  keyDataBox: {
    // TODO move margins to the top level
    marginTop: ({ drawerView }: StyleProps) =>
      drawerView ? theme.spacing(0) : theme.spacing(8),
    [theme.breakpoints.up('sm')]: {
      marginTop: ({ drawerView }: StyleProps) =>
        drawerView ? theme.spacing(0) : theme.spacing(20),
    },
    scrollMarginTop: '8em',
    marginBottom: ({ drawerView }: StyleProps) =>
      drawerView ? theme.spacing(4) : theme.spacing(0),
  },
}));

const KeyDataBox: React.FunctionComponent = () => {
  const drawerView = useIsPDPCarInfoExp();
  const classes = useStyles({ drawerView });

  const i18n = useIntl();
  const { vehicle } = useContext(VehicleContext);
  const items = useKeyDataItems(vehicle);
  const title = i18n.formatMessage({ id: 'pdp.keyDataTitle' });

  return (
    <Box className={classes.keyDataBox} id="product_info">
      <div className="container">
        <h2
          className={
            drawerView
              ? 'body-16-semibold'
              : 'mobile-t3-semibold md:web-t3-semibold mb-10'
          }
        >
          {title}
        </h2>
        <KeyDataList items={items} />
      </div>
    </Box>
  );
};
export default KeyDataBox;
