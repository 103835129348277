import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import PDPTracking from '../utils/PDPTracking';

export const ContactUsBox: React.FC = () => {
  const i18n = useIntl();
  const title = i18n.formatMessage({ id: 'pdp.contactUs.title' });
  const altText = i18n.formatMessage({ id: 'pdp.contactUs.altText' });
  const phoneNumber = i18n.formatMessage({ id: 'pdp.contactUs.phoneNumber' });

  const handlePhoneClick = (event) => {
    if (event.target.tagName === 'A') {
      window.location.href = `tel:${phoneNumber}`;
      PDPTracking.phoneSupportClicked();
    }
  };

  return (
    <div className="bg-snow flex flex-col items-center gap-4 rounded-sm p-6 text-center sm:mt-3 sm:px-14 sm:py-6 md:px-32">
      <img
        src="/assets/contact-us.webp"
        alt={altText}
        className="rounded-full"
        height={40}
        width={40}
      />
      <span className="body-16-semibold">{title}</span>

      <span
        className="body-14-light !mt-2"
        onClick={(event) => handlePhoneClick(event)}
      >
        <FormattedMessage id="pdp.contactUs.description" />
      </span>
    </div>
  );
};
