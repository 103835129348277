import {
  Modal,
  ModalContent,
  ModalHeader,
  ModalSubtitle,
  ModalTitle,
} from '@finn/design-system/atoms/modal';
import { VehicleContext } from '@finn/ua-vehicle';
import React, { useContext } from 'react';

type VehiclesBenefitsModalProps = {
  isOpen: boolean;
  index: number | null;
  onClose(): void;
};

const VehiclesBenefitsModal = ({
  isOpen,
  index,
  onClose,
}: VehiclesBenefitsModalProps) => {
  const { fearAwayItems } = useContext(VehicleContext);
  const { title, description } = fearAwayItems[index] || {};

  return (
    <Modal
      variant="small"
      open={isOpen}
      onOpenChange={(open) => !open && onClose()}
    >
      <ModalContent>
        <ModalHeader>
          <ModalTitle>{title}</ModalTitle>
          <ModalSubtitle className="pb-4">{description}</ModalSubtitle>
        </ModalHeader>
      </ModalContent>
    </Modal>
  );
};

export default VehiclesBenefitsModal;
