import { TIRES_ALL_SEASON, TIRES_SEASONAL } from '@finn/ua-constants';
import { getPowerLabel } from '@finn/ui-modules';
import get from 'lodash/get';
import { IntlShape, useIntl } from 'react-intl';

import { IListItem } from '~/components/KeyData/KeyDataListItem';
import { useCurrentLocale } from '~/hooks/useCurrentLocale';
import useFormattedNumber from '~/hooks/useFormattedNumber';
import { isElectricVehicle } from '~/utils/pdp';

const getTireLabelAndTooltip = (tire: string, intl: IntlShape) => {
  const tireLowercase = tire?.toLowerCase().trim();
  if (TIRES_SEASONAL.includes(tireLowercase)) {
    return {
      label: intl.formatMessage({
        id: 'pdp.keyData.summerWinterLabel',
      }),
      tooltip: intl.formatMessage({
        id: 'pdp.keyData.summerWinterTooltip',
      }),
    };
  }
  if (tireLowercase === TIRES_ALL_SEASON) {
    return {
      label: intl.formatMessage({
        id: 'pdp.keyData.allSeasonLabel',
      }),
      tooltip: intl.formatMessage({
        id: 'pdp.keyData.allSeasonTooltip',
      }),
    };
  }

  return { label: null, tooltip: null };
};

const useKeyDataItems = (vehicle): IListItem[] => {
  const i18n = useIntl();
  const { region } = useCurrentLocale();
  const consumption = useFormattedNumber(get(vehicle, 'consumption', 0));
  const power = Number(vehicle.power ?? 0);
  const fuel = get(vehicle, 'fuel', '');
  const isElectric = isElectricVehicle(fuel);
  const powerLabel = getPowerLabel(i18n, power, region, isElectric);
  const shouldShowEVRange = get(vehicle, 'ev_range') && isElectric;

  // TODO app-level way to control units
  const consumptionUnit = `${isElectric ? 'kWh' : 'l'} / 100km`;
  const distanceUnit = 'km';

  return [
    {
      id: 1,
      label: get(vehicle, 'model_year'),
      title: i18n.formatMessage({
        id: 'pdp.keyData.modelYear',
      }),
    },
    {
      id: 2,
      label: get(vehicle, 'cartype'),
      title: i18n.formatMessage({ id: 'pdp.keyData.cartype' }),
    },
    {
      id: 3,
      label: `${get(vehicle, 'seats') ?? '-'}/${get(vehicle, 'doors') ?? '-'}`,
      title: i18n.formatMessage({
        id: 'pdp.keyData.seats-doors',
      }),
    },
    {
      id: 4,
      label: get(vehicle, 'engine'),
      title: i18n.formatMessage({
        id: 'pdp.keyData.engine',
      }),
    },
    {
      id: 5,
      label: get(vehicle, 'fuel'),
      title: i18n.formatMessage({
        id: 'pdp.keyData.fuel',
      }),
    },
    {
      id: 6,
      label: get(vehicle, 'config_drive'),
      title: i18n.formatMessage({
        id: 'pdp.keyData.drive',
      }),
    },
    {
      id: 7,
      label: get(vehicle, 'gearshift'),
      title: i18n.formatMessage({ id: 'pdp.keyData.gearshift' }),
    },
    {
      id: 8,
      label: powerLabel,
      title: i18n.formatMessage({ id: 'pdp.keyData.power' }),
    },
    {
      id: 9,
      label: `${consumption} ${consumptionUnit}`,
      title: i18n.formatMessage({ id: 'pdp.keyData.consumption' }),
    },
    {
      id: 11,
      label: shouldShowEVRange
        ? `${get(vehicle, 'ev_range')} ${distanceUnit}`
        : '',
      title: i18n.formatMessage({
        id: 'pdp.keyData.range',
      }),
    },
    {
      id: 12,
      label: getTireLabelAndTooltip(get(vehicle, 'tires'), i18n).label,
      title: i18n.formatMessage({
        id: 'pdp.keyData.tires',
      }),
      additionalInfo: getTireLabelAndTooltip(get(vehicle, 'tires'), i18n)
        .tooltip,
    },
  ];
};

export default useKeyDataItems;
