import { GenericVehicleDetails } from '@finn/ua-vehicle';
import { isServer } from '@finn/ui-utils';
import { get } from 'lodash';

import { removeGermanTax } from '~/utils/checkout';

export const getTotalPrice = (
  vehicle: GenericVehicleDetails,
  term: number,
  kilometerPackage: number | undefined,
  isForBusiness: boolean = false,
  isDownpayment: boolean = false
) => {
  if (isServer()) {
    return '--';
  }

  const priceKey = isDownpayment ? 'downpayment_prices' : 'price';

  const price =
    get(vehicle, `${priceKey}.available_price_list.b2c_${term}`) ||
    get(vehicle, `${priceKey}.b2c_${term}`, 0);
  const kmPackagePrice = get(vehicle, `price.extra_${kilometerPackage}`, 0);

  const totalPrice = price + kmPackagePrice;

  if (isForBusiness) {
    return removeGermanTax(totalPrice);
  }

  return totalPrice;
};
