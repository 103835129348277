import { makeStyles } from '@material-ui/core';

export const useNotifyMeModalStyles = makeStyles((theme) => ({
  paperRoot: {
    padding: theme.spacing(7, 3),
    [theme.breakpoints.up('sm')]: {
      padding: theme.spacing(7),
      maxWidth: theme.spacing(65),
    },
  },
  paperRootUnavailableModal: {
    padding: theme.spacing(7, 3),
    [theme.breakpoints.up('sm')]: {
      padding: theme.spacing(7),
      maxWidth: theme.spacing(72.5),
    },
  },
  nameInputGroup: {
    display: 'flex',
    gap: theme.spacing(2),
    marginBottom: theme.spacing(3),
    '& > *': {
      flexGrow: 1,
    },
  },
  relative: {
    position: 'relative',
  },
  absolute: {
    position: 'absolute',
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(3),
    top: theme.spacing(3),
    padding: theme.spacing(1),
    borderRadius: '50%',
    transition: 'all 350ms',
  },
}));
