import { useCurrentLocale } from '@finn/ui-utils';
import { useContext, useMemo } from 'react';
import { useIntl } from 'react-intl';

import { type Breadcrumb } from '../components/Breadcrumbs/Breadcrumbs';
import { FilterContext, FilterContextType } from '../contexts/Filter';
import VehicleContext, { VehicleContextType } from '../contexts/Vehicle';
import { getUrlFromFilter } from '../utils/filter';

export { type Breadcrumb };

export const useProductPageBreadcrumbs = (): Breadcrumb[] => {
  const i18n = useIntl();
  const { vehicle } = useContext<VehicleContextType>(VehicleContext);
  const { breadcrumbs: filterBreadcrumbs } =
    useContext<FilterContextType>(FilterContext);
  const { locale } = useCurrentLocale();

  const breadcrumbs = useMemo(() => {
    if (!vehicle || !vehicle.brand || !vehicle.model) {
      return filterBreadcrumbs as Breadcrumb[];
    }

    const brandUrl = getUrlFromFilter({
      brands: [vehicle.brand.id.toLowerCase()],
    });
    const modelUrl = getUrlFromFilter({
      brands: [vehicle.brand.id.toLowerCase()],
      models: [vehicle.model.toLowerCase()],
    });

    const homeTitle = i18n.formatMessage({ id: 'general.home' });

    return [
      { title: homeTitle, url: `/${locale}` },
      { title: vehicle.brand.id, url: `/${locale}${brandUrl}` },
      { title: vehicle.model, url: `/${locale}${modelUrl}` },
    ];
  }, [filterBreadcrumbs, i18n, vehicle]);

  return breadcrumbs;
};
