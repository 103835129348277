import * as Yup from 'yup';

import { NotifyMeFormVariant } from './useNotifyMeModalStore';

export type FormInputs = {
  firstName: string | null;
  lastName: string | null;
  email: string | null;
  zipCode: string | null;
  password: string | null;
};

export type FormInputsDeal = Omit<FormInputs, 'password'>;

export type FormInputsEmail = Pick<FormInputs, 'email'>;

export type FormInputsLogin = Pick<FormInputs, 'email' | 'password'>;

export const initialValuesDeal: FormInputsDeal = {
  firstName: '',
  lastName: '',
  email: '',
  zipCode: '',
};

const createValidationSchemaDeal = () =>
  Yup.object().shape<FormInputsDeal>({
    firstName: Yup.string().nullable().required('yup.required'),
    lastName: Yup.string().nullable().required('yup.required'),
    email: Yup.string().email('yup.email').nullable().required('yup.required'),
    zipCode: Yup.string().nullable(),
  });

const createValidationSchemaEmail = () =>
  Yup.object().shape<FormInputsEmail>({
    email: Yup.string().email('yup.email').required('yup.required'),
  });

const createValidationSchemaLogin = () =>
  Yup.object().shape<FormInputsLogin>({
    email: Yup.string().email('yup.email').required('yup.required'),
    password: Yup.string().required('yup.required'),
  });

export const getValidationScemaForFormType = (
  formType: NotifyMeFormVariant
): Yup.ObjectSchema<
  object & (FormInputsDeal | FormInputsLogin | FormInputsEmail)
> => {
  switch (formType) {
    case 'email': {
      return createValidationSchemaEmail();
    }
    case 'login': {
      return createValidationSchemaLogin();
    }
    default: {
      return createValidationSchemaDeal();
    }
  }
};
