import { getForBusinessCookie } from '@finn/ui-modules';
import { useCurrentLocale } from '@finn/ui-utils';
import { useContext, useMemo } from 'react';

import VehicleContext from '~/contexts/Vehicle';

export const useFilteredFearAwayItems = () => {
  const { fearAwayItems } = useContext(VehicleContext);
  const { isUSA } = useCurrentLocale();
  const isForBusiness = getForBusinessCookie();

  const filteredFearAwayItems = useMemo(() => {
    // if it's USA and for business, remove the first item
    if (isUSA && isForBusiness) {
      // return new array removing the first item
      return [fearAwayItems[0], fearAwayItems[2]];
    }

    return fearAwayItems;
  }, [fearAwayItems, isForBusiness, isUSA]);

  return { filteredFearAwayItems };
};
