import { ArrowDirection, CarouselArrow } from '@finn/ui-modules';
import { makeStyles } from '@material-ui/core';
import classnames from 'classnames';
import React, { MouseEventHandler } from 'react';

import PDPTracking from '~/components/ProductDetails/utils/PDPTracking';
// TODO design-system: refactor !important styles after migration to new typography APP-1443

const useStyles = makeStyles((theme) => ({
  arrowButton: {
    position: 'absolute',
    zIndex: theme.customZIndices.pdpFullscreenGallery,
    bottom: theme.spacing(3),
    [theme.breakpoints.up('md')]: {
      position: 'static',
    },
  },
  leftArrowButton: {
    left: theme.spacing(2),
    marginRight: theme.spacing(1),
    [theme.breakpoints.up('md')]: {
      order: 1,
    },
  },
  rightArrowButton: {
    right: theme.spacing(2),
    marginLeft: theme.spacing(1),
    [theme.breakpoints.up('md')]: {
      order: 3,
    },
  },
}));

type Props = {
  direction: ArrowDirection;
  onClick?: MouseEventHandler | undefined;
};

const ImageGalleryArrow: React.FC<Props> = ({ direction, onClick }) => {
  const classes = useStyles({ direction });

  const handleClick = (e: React.MouseEvent) => {
    onClick(e);
    if (direction === ArrowDirection.left) {
      PDPTracking.previousArrowClicked();
    } else {
      PDPTracking.nextArrowClicked();
    }
  };

  return (
    <CarouselArrow
      direction={direction}
      onClick={(e) => handleClick(e)}
      className={classnames(
        classes.arrowButton,
        'active:!bg-cotton',
        direction === ArrowDirection.left
          ? classes.leftArrowButton
          : classes.rightArrowButton
      )}
      arrowSize={20}
    />
  );
};
export default ImageGalleryArrow;
