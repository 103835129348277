import { Button } from '@finn/design-system/atoms/button';
import {
  Modal,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalTitle,
} from '@finn/design-system/atoms/modal';
import { parseToHtml } from '@finn/ui-utils';
import React, { useCallback } from 'react';

import { PDPData } from '~/types/cosmicModules';

import { useConfigureStore } from '../../ConfigurePage/ConfigurePageStore';

type Props = {
  open: boolean;
  onClose(): void;
  pageData: PDPData;
};

const DirectCheckoutConfirmation: React.FC<Props> = ({
  open,
  onClose,
  pageData,
}) => {
  const { onDirectCheckoutContinue } = useConfigureStore();

  const title =
    pageData?.metadata?.content.direct_checkout_confirmation_modal.metadata
      .modal_title;
  const acceptButtonText =
    pageData?.metadata?.content.direct_checkout_confirmation_modal.metadata
      .accept_button_text;
  const rejectButtonText =
    pageData?.metadata?.content.direct_checkout_confirmation_modal.metadata
      .reject_button_text;
  const description =
    pageData?.metadata?.content.direct_checkout_confirmation_modal.content;

  const handleClose = useCallback(() => {
    const isApp = Boolean(
      typeof window !== 'undefined' ? window?.nativeAppSDK : false
    );
    // inside of the app we do full redirect, which triggers the handleClose each time together with submit
    if (!isApp) {
      onDirectCheckoutContinue({});
    }
    onClose();
  }, [onClose, onDirectCheckoutContinue]);

  return (
    <Modal
      variant="small"
      open={open}
      onOpenChange={(isOpen) => !isOpen && handleClose()}
    >
      <ModalContent>
        <ModalHeader>
          <ModalTitle>{title}</ModalTitle>
        </ModalHeader>

        <div className="body-16-regular [&_a]:link-16 [&_a:after]:!hidden [&_a:before]:!hidden">
          {parseToHtml(description)}
        </div>
        <ModalFooter data-appid="direct-checkout-button-group">
          <Button
            onClick={() => {
              onDirectCheckoutContinue({ accepted: true });
              onClose();
            }}
          >
            {acceptButtonText}
          </Button>
          <Button
            onClick={() => {
              onDirectCheckoutContinue({ accepted: false });
              onClose();
            }}
            variant="outline"
          >
            {rejectButtonText}
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};
export default DirectCheckoutConfirmation;
