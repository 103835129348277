import { interactionTrackingEvent, TrackingEventName } from '@finn/ui-modules';

const PDPTracking = {
  primaryImageClicked: () => {
    interactionTrackingEvent(TrackingEventName.GALLERY_IMAGE_CLICKED, {
      type: 'primary',
    });
  },
  secondaryImagesClicked: () => {
    interactionTrackingEvent(TrackingEventName.GALLERY_IMAGE_CLICKED, {
      type: 'secondary',
    });
  },
  nextArrowClicked: () => {
    interactionTrackingEvent(TrackingEventName.GALLERY_SWIPED, {
      mode: 'arrow',
      direction: 'right',
    });
  },
  previousArrowClicked: () => {
    interactionTrackingEvent(TrackingEventName.GALLERY_SWIPED, {
      mode: 'arrow',
      direction: 'left',
    });
  },
  nextSwipe: () => {
    interactionTrackingEvent(TrackingEventName.GALLERY_SWIPED, {
      direction: 'right',
      mode: 'swipe',
    });
  },
  previousSwipe: () => {
    interactionTrackingEvent(TrackingEventName.GALLERY_SWIPED, {
      direction: 'left',
      mode: 'swipe',
    });
  },
  imageZoomed: () => {
    interactionTrackingEvent(TrackingEventName.GALLERY_IMAGE_ZOOMED, {});
  },
  thumbnailsClicked: () => {
    interactionTrackingEvent(TrackingEventName.GALLERY_IMAGE_CLICKED, {
      type: 'thumbnail',
    });
  },
  galleryCloseClicked: () => {
    interactionTrackingEvent(TrackingEventName.GALLERY_CLOSED, {});
  },
  environmentalDetailsClicked: () => {
    interactionTrackingEvent(TrackingEventName.USER_BEHAVIOUR, {
      category: 'Data Interaction',
      label: 'Environmental Details',
      action: 'Clicked',
    });
  },
  stickyAddToCartClicked: () => {
    interactionTrackingEvent(TrackingEventName.USER_BEHAVIOUR, {
      category: 'Sticky Navigation Interaction',
      label: 'Add to Card',
      action: 'Clicked',
    });
  },
  colorClicked: (eventProperties = {}) => {
    interactionTrackingEvent(TrackingEventName.COLOR_SELECTED, {
      ...eventProperties,
    });
  },
  contractTermClicked: (eventProperties = {}) => {
    return interactionTrackingEvent(TrackingEventName.TERM_SELECTED, {
      ...eventProperties,
    });
  },
  distanceClicked: (eventProperties = {}) => {
    interactionTrackingEvent(TrackingEventName.DISTANCE_PACKAGE_SELECTED, {
      ...eventProperties,
    });
  },
  equipmentExpandClick: (eventProperties = {}) => {
    interactionTrackingEvent(TrackingEventName.PDP_INTERACTION, {
      category: 'Interaction',
      label: 'Configuration Dropdown',
      action: 'Open Dropdown',
      ...eventProperties,
    });
  },
  equipmentCollapseClick: (eventProperties = {}) => {
    interactionTrackingEvent(TrackingEventName.PDP_INTERACTION, {
      category: 'Interaction',
      label: 'Configuration Dropdown',
      action: 'Close Dropdown',
      ...eventProperties,
    });
  },
  pdfConfigClicked: (eventProperties = {}) => {
    interactionTrackingEvent(TrackingEventName.PDP_INTERACTION, {
      category: 'Interaction',
      label: 'PDF Configuration',
      action: 'Download',
      ...eventProperties,
    });
  },
  insuranceModalSubmitted: (eventProperties = {}) => {
    interactionTrackingEvent(TrackingEventName.PDP_INTERACTION, {
      category: 'Interaction',
      label: 'Lead Form Submitted',
      action: 'Click',
      ...eventProperties,
    });
  },
  insuranceModalDismissed: (eventProperties = {}) => {
    interactionTrackingEvent(TrackingEventName.PDP_INTERACTION, {
      category: 'Interaction',
      label: 'Lead Form Dismissed',
      action: 'Click',
      ...eventProperties,
    });
  },
  helpOptionClicked: (type: string) => {
    interactionTrackingEvent(TrackingEventName.HELP_OPTION_CLICKED, { type });
  },
  phoneSupportClicked: () => {
    interactionTrackingEvent(TrackingEventName.PHONE_SUPPORT_CLICKED, {});
  },
};

export default PDPTracking;
