import CosmicModule from '@finn/auto-ui/ui-modules/CosmicModule';
import { ProductSliderCosmicData } from '@finn/auto-ui/ui-modules/ProductSlider/types';
import { SpacingWrapper } from '@finn/platform-modules';
import React from 'react';

import { PDPModules } from '~/types/cosmicModules';

export const ProductSlider: React.FC<{ pdpModules: PDPModules }> = ({
  pdpModules,
}) => {
  const productSliderModule = pdpModules.metadata.modules.find(
    (module) => module.slug === 'carslider-standardsorting'
  ) as ProductSliderCosmicData;

  if (!productSliderModule) {
    return null;
  }

  productSliderModule.metadata.title = 'Das könnte Ihnen auch gefallen';

  return (
    <SpacingWrapper>
      <CosmicModule data={productSliderModule} />
    </SpacingWrapper>
  );
};
