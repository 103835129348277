import { useConfigureStore } from '@finn/auto-ui/components';
import { Button } from '@finn/design-system/atoms/button';
import {
  Modal,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalTitle,
} from '@finn/design-system/atoms/modal';
import { VehicleContext } from '@finn/ua-vehicle';
import { calculateTotalPrice } from '@finn/ui-modules';
import { getFormattedPrice } from '@finn/ui-utils';
import React, { useContext } from 'react';
import { useIntl } from 'react-intl';

type Props = {
  isOpen: boolean;
  onClose: () => void;
};

export const DownpaymentOptionsModal: React.FunctionComponent<Props> = ({
  isOpen,
  onClose,
}) => {
  const i18n = useIntl();
  const { vehicle } = useContext(VehicleContext);

  const {
    term,
    onContinue,
    kilometerPackage,
    isLoading: isLoadingFromModal,
  } = useConfigureStore();

  const totalPrice = calculateTotalPrice({ vehicle, term, kilometerPackage });
  const price = getFormattedPrice(i18n, totalPrice);

  const downPaymentContent = i18n.formatMessage(
    { id: 'pdp.downPayment.modalContent' },
    { price, brand: vehicle.brand.id, model: vehicle.model }
  );

  return (
    <Modal
      variant="small"
      open={isOpen}
      onOpenChange={(open) => !open && onClose()}
    >
      <ModalContent>
        <ModalHeader>
          <ModalTitle>
            {i18n.formatMessage({ id: 'pdp.downPayment.modalTitle' })}
          </ModalTitle>
        </ModalHeader>
        <div className="body-14-regular">{downPaymentContent}</div>
        <ModalFooter>
          <Button
            className="w-full"
            onClick={onContinue}
            loading={isLoadingFromModal}
          >
            {i18n.formatMessage({ id: 'pdp.downPayment.modalButton' })}
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};
