import { Check } from '@finn/design-system/icons/check';
import { interactionTrackingEvent, TrackingEventName } from '@finn/ua-tracking';
import { Box, makeStyles, Theme } from '@material-ui/core';
import React, { useState } from 'react';

import { useFilteredFearAwayItems } from './useFilteredFearAwayItems';
import VehiclesBenefitsModal from './VehiclesBenefitsModal';

const useStyles = makeStyles((theme: Theme) => ({
  benefitItem: {
    display: 'flex',
    alignItems: 'center',
  },
  icon: {
    flexShrink: 0,
    width: 16,
    height: 16,
    marginRight: theme.spacing(1),
  },
}));

const ClickableVehiclesBenefits: React.FunctionComponent = () => {
  const classes = useStyles();
  const [modalData, setModalData] = useState({ isShown: false, index: null });
  const { filteredFearAwayItems } = useFilteredFearAwayItems();

  const handleBenefitClick = (index: number) => () => {
    setModalData({ isShown: true, index });
    interactionTrackingEvent(TrackingEventName.MODAL_OPENED, {
      name: filteredFearAwayItems[index].title,
    });
  };

  const handleModalClose = () => {
    setModalData((prevModalData) => ({ ...prevModalData, isShown: false }));
    interactionTrackingEvent(TrackingEventName.MODAL_CLOSED, {
      name: filteredFearAwayItems[modalData.index]?.title,
    });
  };

  return (
    <>
      {filteredFearAwayItems.map(({ title }, index) => (
        <Box paddingBottom={2} key={title} className={classes.benefitItem}>
          <Check className={classes.icon} />
          <Box onClick={handleBenefitClick(index)}>
            <p className="body-16-semibold cursor-pointer underline">{title}</p>
          </Box>
        </Box>
      ))}
      <VehiclesBenefitsModal
        isOpen={modalData.isShown}
        index={modalData.index}
        onClose={handleModalClose}
      />
    </>
  );
};

export default ClickableVehiclesBenefits;
