import { GenericVehicleDetails, getConfigLineLabel } from '@finn/ua-vehicle';
import { Image } from '@finn/ui-modules';
import { cn, getCloudinaryImgUrl, getCloudinarySrcSet } from '@finn/ui-utils';
import { makeStyles, Theme } from '@material-ui/core';

const IMAGE_WIDTH = 146;
const IMAGE_HEIGHT = 96;

const useStyles = makeStyles((theme: Theme) => ({
  imageWrapper: {
    textAlign: 'center',
    paddingTop: theme.spacing(2),
    width: 146,
  },
  image: {
    maxWidth: '90%',
  },
  vehicleInfo: {
    display: 'flex',
    gap: theme.spacing(2),
  },
  vehicleText: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(1),
    justifyContent: 'center',
  },
}));

export const ConfigureVehicleHeader = ({
  vehicle,
}: {
  vehicle: GenericVehicleDetails;
}) => {
  const classes = useStyles();
  const vehiclePictureURL = vehicle.picture.url;
  const doesProductImageExist = Boolean(vehiclePictureURL);
  const vehicleImageUrl = doesProductImageExist
    ? getCloudinaryImgUrl(vehiclePictureURL, {
        w: IMAGE_WIDTH,
        h: IMAGE_HEIGHT,
        c: 'fill',
        dpr: 2,
        fl: 'progressive',
      })
    : '';
  const srcSet =
    vehicleImageUrl && getCloudinarySrcSet(vehicleImageUrl, [IMAGE_WIDTH]);

  const vehicleAltText = `${vehicle.brand?.id} ${vehicle.model}`;

  return (
    <div className={classes.vehicleInfo}>
      <div className={cn(classes.imageWrapper, 'bg-snow')}>
        <Image
          src={vehicleImageUrl}
          srcSet={srcSet}
          sizes={`142px`}
          alt={vehicleAltText}
          className={classes.image}
        />
      </div>

      <div className={classes.vehicleText}>
        <span className="body-16-semibold">
          {vehicle.brand.id} {vehicle.model}
        </span>

        <span className="body-14-regular">{getConfigLineLabel(vehicle)}</span>
      </div>
    </div>
  );
};
