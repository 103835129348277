import { useProductPageBreadcrumbs } from '@finn/auto-ui/hooks/useProductPageBreadcrumbs';
import {
  interactionTrackingEvent,
  LinkLocation,
  TrackingEventName,
} from '@finn/ua-tracking';
import React from 'react';

import Breadcrumbs from '../Breadcrumbs';

interface BreadcrumbsProps {
  className?: string;
}

const clickDestinations = ['Home', 'Make', 'Model'];

const trackBreadcrumbClick = (_, index: number) => {
  interactionTrackingEvent(TrackingEventName.LINK_CLICKED, {
    location: LinkLocation.PDP_BREADCRUMB,
    name: clickDestinations[index],
  });
};

const ProductPageBreadcrumbs: React.FC<BreadcrumbsProps> = ({
  className = '',
}) => {
  const breadcrumbs = useProductPageBreadcrumbs();

  if (!breadcrumbs) {
    return null;
  }

  return (
    <Breadcrumbs
      className={`mb-4 ${className}`}
      breadcrumbs={breadcrumbs}
      onBreadcrumbClick={trackBreadcrumbClick}
    />
  );
};

export default ProductPageBreadcrumbs;
