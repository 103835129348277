import {
  Modal,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalTitle,
} from '@finn/design-system/atoms/modal';
import { interactionTrackingEvent, TrackingEventName } from '@finn/ua-tracking';
import React, { useCallback } from 'react';
import { useIntl } from 'react-intl';

interface IProps {
  open: boolean;
  onClose: () => void;
}

export const DownpaymentTooltipModal: React.FunctionComponent<IProps> = ({
  open,
  onClose,
}) => {
  const i18n = useIntl();

  const handleModalClose = useCallback(() => {
    interactionTrackingEvent(TrackingEventName.MODAL_CLOSED, {
      name: 'downpayment tooltip',
    });
    onClose();
  }, [onClose]);

  return (
    <Modal
      open={open}
      onOpenChange={(nextPrev) => !nextPrev && handleModalClose()}
    >
      <ModalContent>
        <ModalHeader>
          <ModalTitle>
            {i18n.formatMessage({
              id: 'pdp.experiment.downpaymentTooltip.howDoesItWork',
            })}
          </ModalTitle>
        </ModalHeader>
        <p className="body-16-light">
          {i18n.formatMessage({
            id: 'pdp.experiment.downpaymentTooltip.description',
          })}
        </p>
      </ModalContent>
      <ModalFooter />
    </Modal>
  );
};
