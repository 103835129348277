import {
  ContractDuration,
  DistanceOptions,
} from '@finn/auto-ui/components/ConfigurePage';
import { useConfigureStore } from '@finn/auto-ui/components/ConfigurePage/ConfigurePageStore';
import { ContinueButton } from '@finn/auto-ui/components/ConfigurePage/ContinueButton';
import {
  Drawer,
  DrawerContent,
  DrawerFooter,
} from '@finn/design-system/atoms/drawer';
import { Features, useIsABVariant, useIsCVariant } from '@finn/ua-featureflags';
import {
  interactionTrackingEvent,
  TrackingEventName,
  useTrackingStore,
} from '@finn/ua-tracking';
import { VehicleContext } from '@finn/ua-vehicle';
import { getForBusinessCookie, usePreDiscountRawValue } from '@finn/ui-modules';
import { getFormattedPrice } from '@finn/ui-utils';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import { useIntl } from 'react-intl';

import { usePartnerDiscount } from '~/hooks/usePartnerDiscount';

import { useMonthlySubscriptionCost } from '../PriceBreakdown/hooks/useMonthlySubscriptionCost';
import { ConfigureVehicleHeader } from './ConfigureVehicleHeader';
import { DownpaymentOptionsModal } from './DownpaymentModal';
import { DownPaymentSection } from './DownPaymentSection';
import { PriceDisplay } from './PriceDisplay';

enum ConfigureSteps {
  DownPaymentStep = 1,
  DistanceOptionsStep = 2,
}

type ConfigureVehicleModalProps = {
  open: boolean;
  onClose: () => void;
  loadingEdit?: boolean;
  handleEditConfig?: () => void;
};

export const ConfigureVehicleModal: React.FC<ConfigureVehicleModalProps> = ({
  open,
  onClose,
  handleEditConfig,
  loadingEdit,
}) => {
  const intl = useIntl();
  const partnerDiscount = usePartnerDiscount();
  const track = useTrackingStore((state) => state.track);

  useEffect(() => {
    if (partnerDiscount) {
      track(TrackingEventName.PCP_PARTNER_DISCOUNT_SHOWED, {
        additionalProps: { partnerDiscount },
      });
    }
  }, [partnerDiscount, track]);

  const { vehicle } = useContext(VehicleContext);

  const {
    term,
    onContinue,
    downPaymentAmount,
    downPayment: isDownPayment,
    isLoading: isLoadingFromStore,
  } = useConfigureStore();

  const [isFakeDoorOpen, setIsFakeDoorOpen] = useState(false);
  const [currentStep, setCurrentStep] = useState(
    ConfigureSteps.DownPaymentStep
  );

  const isCheckoutEditModal = Boolean(handleEditConfig);

  const isMultiStep =
    useIsABVariant(Features.ExpPCPMultipleSteps) && !isCheckoutEditModal;
  const isBVariant = useIsABVariant(Features.ExpDownPaymentOptions);
  const isCVariant = useIsCVariant(Features.ExpDownPaymentOptions);

  const isExpDownPayment = isBVariant || isCVariant;

  const isForBusiness = getForBusinessCookie();

  const { total, monthlyCost } = useMonthlySubscriptionCost(vehicle);
  let totalWithDiscount = total;
  let preDiscountPrice = usePreDiscountRawValue(
    vehicle,
    monthlyCost,
    term,
    isForBusiness,
    isDownPayment
  );

  if (partnerDiscount > 0) {
    if (!preDiscountPrice) {
      preDiscountPrice = total;
    }
    totalWithDiscount = total - total * partnerDiscount;
  }

  const originalPriceFormatted = getFormattedPrice(
    intl,
    preDiscountPrice || 0,
    0
  );
  const finalPriceFormatted = getFormattedPrice(intl, totalWithDiscount, 0);

  const subtitle = intl.formatMessage({
    id: `pdp.price.${isForBusiness ? 'b2b' : 'b2c'}_vat`,
  });

  const isShortPreDiscountPrice = preDiscountPrice?.toString().length === 4;

  const handleModalClose = useCallback(() => {
    interactionTrackingEvent(TrackingEventName.MODAL_CLOSED, {
      name: 'configure',
    });
    onClose();
  }, [onClose]);

  const handleFinalStep = useCallback(() => {
    if (isExpDownPayment && downPaymentAmount > 1200) {
      interactionTrackingEvent(TrackingEventName.MODAL_OPENED, {
        name: 'downpayment options',
      });
      setIsFakeDoorOpen(true);
    } else {
      onContinue();
    }
  }, [isExpDownPayment, downPaymentAmount, onContinue]);

  const handleMultiStep = useCallback(() => {
    switch (currentStep) {
      case ConfigureSteps.DownPaymentStep:
        setCurrentStep(ConfigureSteps.DistanceOptionsStep);
        break;
      case ConfigureSteps.DistanceOptionsStep:
        handleFinalStep();
        break;
      default:
        onContinue();
    }
  }, [currentStep, handleFinalStep, onContinue]);

  const handleContinue = useCallback(() => {
    if (handleEditConfig) {
      handleEditConfig();

      return;
    }
    if (isMultiStep) {
      handleMultiStep();
    } else {
      handleFinalStep();
    }
  }, [handleEditConfig, isMultiStep, handleMultiStep, handleFinalStep]);

  const handleBack = useCallback(() => {
    if (isMultiStep && currentStep > ConfigureSteps.DownPaymentStep) {
      setCurrentStep(ConfigureSteps.DownPaymentStep);
    }
  }, [isMultiStep, currentStep]);

  useEffect(() => {
    if (isMultiStep) {
      interactionTrackingEvent(TrackingEventName.CTA_CLICKED, {
        name: `Configure step - ${currentStep}`,
      });
    }
  }, [currentStep, isMultiStep]);

  const isPrevStateAvailable =
    isMultiStep && currentStep > ConfigureSteps.DownPaymentStep;

  return (
    <Drawer open={open} onClose={handleModalClose}>
      <DrawerContent
        title={intl.formatMessage({ id: 'pdp.configure.generalTitle' })}
        onBack={isPrevStateAvailable ? handleBack : undefined}
      >
        <ConfigureVehicleHeader vehicle={vehicle} />
        {isMultiStep ? (
          <>
            {currentStep === ConfigureSteps.DownPaymentStep && (
              <>
                <DownPaymentSection
                  term={term}
                  isExpDownPayment={isExpDownPayment}
                  isCheckoutEditModal={isCheckoutEditModal}
                />
                <ContractDuration />
              </>
            )}
            {currentStep === ConfigureSteps.DistanceOptionsStep && (
              // Appear from left to right
              <div className="animate-slide-in-from-left flex flex-col items-start">
                <DistanceOptions />
              </div>
            )}
          </>
        ) : (
          <>
            <DownPaymentSection
              term={term}
              isExpDownPayment={isExpDownPayment}
              isCheckoutEditModal={isCheckoutEditModal}
            />
            <ContractDuration />
            <DistanceOptions />
          </>
        )}
        <DrawerFooter>
          <PriceDisplay
            subtitle={subtitle}
            finalPrice={finalPriceFormatted}
            originalPrice={originalPriceFormatted}
            isShortPrice={isShortPreDiscountPrice}
            hasOriginalPrice={Boolean(preDiscountPrice)}
          />

          <div className="w-1/2">
            <ContinueButton
              isInPDP
              testId="submit-config"
              onClick={handleContinue}
              isLoading={isLoadingFromStore || loadingEdit}
            />
          </div>

          <DownpaymentOptionsModal
            isOpen={isFakeDoorOpen}
            onClose={() => setIsFakeDoorOpen(false)}
          />
        </DrawerFooter>
      </DrawerContent>
    </Drawer>
  );
};
