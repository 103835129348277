import React, { memo } from 'react';

import AvailabilityElements from './AvailabilityElements';
import SubscriptionPrice from './SubscriptionPrice';

interface IProps {
  isAvailable: boolean;
  renderSubmitButton(): React.ReactNode;
}

const PriceArea: React.FC<IProps> = ({ isAvailable, renderSubmitButton }) => {
  return (
    <div data-cy="price-area">
      <SubscriptionPrice />

      {isAvailable && (
        <AvailabilityElements renderSubmitButton={renderSubmitButton} />
      )}
    </div>
  );
};

export default memo(PriceArea);
