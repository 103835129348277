import { ClockOutlined } from '@finn/design-system/icons/clock-outlined';
import { Features, useIsCVariant } from '@finn/ua-featureflags';
import { cn } from '@finn/ui-utils';
import { makeStyles, Theme } from '@material-ui/core';
import React, { useContext } from 'react';

import VehicleContext from '~/contexts/Vehicle';

import AvailabilityText from '../AvailabilityText';
import LeftInStock from '../LeftInStock';

const useStyles = makeStyles((theme: Theme) => ({
  leftInStockWrapper: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(0.4),
  },
}));

interface IProps {
  renderSubmitButton(): React.ReactNode;
}

const AvailabilityElements: React.FunctionComponent<IProps> = ({
  renderSubmitButton,
}) => {
  const classes = useStyles();
  const { vehicle } = useContext(VehicleContext);
  const isExpPDPAvailabilityLabelCVariant = useIsCVariant(
    Features.ExpPDPAvailabilityLabel
  );

  return (
    <>
      {renderSubmitButton()}

      <div className={cn(classes.leftInStockWrapper, 'space-y-2')}>
        <LeftInStock
          sm={isExpPDPAvailabilityLabelCVariant}
          productStockTotal={vehicle.product_stock_total}
        />
        {isExpPDPAvailabilityLabelCVariant && (
          <div className="flex items-center space-x-2">
            <ClockOutlined width={16} height={16} />
            <AvailabilityText className="body-14-semibold !mb-0 !text-black" />
          </div>
        )}
      </div>
    </>
  );
};

export default AvailabilityElements;
