import { Button } from '@finn/design-system/atoms/button';
import { cn } from '@finn/ui-utils';
import { Divider } from '@material-ui/core';
import { useRouter } from 'next/router';
import React, { useContext } from 'react';
import { useIntl } from 'react-intl';

import { PLPRoute } from '~/types/general';

// TODO design-system: refactor !important styles after migration to new typography APP-1443
import { AddToCartContext } from './AddToCartProvider';

type Props = {
  classes: Record<string, string>;
};

const ComingSoonSection: React.FC<Props> = ({ classes }) => {
  const i18n = useIntl();
  const router = useRouter();
  const { onAddToCart } = useContext(AddToCartContext);

  return (
    <>
      <Divider className={cn(classes.dividerPearl, '!bg-pearl')} />
      <h5 className="global-t5-semibold my-2">
        {i18n.formatMessage({ id: 'pdp.comingSoon.beTheFirst' })}
      </h5>
      <p className="body-16-light my-4">
        {i18n.formatMessage({
          id: 'pdp.comingSoon.thisCarIsComingSoon',
        })}
      </p>
      <div className={classes.mb2}>
        <Button data-cy="reserve-coming-soon" onClick={onAddToCart}>
          {i18n.formatMessage({
            id: 'pdp.comingSoon.openPopupLabel',
          })}
        </Button>
      </div>
      <div>
        <Button
          data-cy="browse-more"
          variant="outline"
          onClick={() => {
            router.push(PLPRoute.SUBSCRIPTION);
          }}
        >
          {i18n.formatMessage({
            id: 'pdp.comingSoon.browseMoreLabel',
          })}
        </Button>
      </div>
    </>
  );
};
export default ComingSoonSection;
