import { checkEmailExistence } from '@finn/auto-ui/services/emailCheck';
import {
  ComingSoonDealCreationBody,
  LocationUnavailableDealCreationBody,
} from '@finn/auto-ui/types/checkout';
import { login } from '@finn/ua-auth';
import { create } from 'zustand';
import { devtools } from 'zustand/middleware';
import { immer } from 'zustand/middleware/immer';

export type NotifyMeFormVariant = 'login' | 'guest' | 'email' | 'none';

type NotifyMeFormSubmissionVariant =
  | 'email'
  | 'login'
  | 'comingSoon'
  | 'locationUnavailable'
  | undefined;

type NotifyMeModalState = {
  activeSubmission: NotifyMeFormSubmissionVariant;
  activeForm: NotifyMeFormVariant;
  submitError: Boolean | null;
  subscribedList: string[];
  addToSubscribedList: (key: string) => void;
  setSubmitError: (hasError: Boolean | null) => void;
  setActiveForm: (form: NotifyMeFormVariant) => void;
  checkEmail: (email: string) => Promise<void>;
  submitLogin: (email: string, password: string) => Promise<void>;
  submitComingSoonDeal?: (payload: ComingSoonDealCreationBody) => Promise<void>;
  submitLocationUnavailableDeal?: (
    payload: LocationUnavailableDealCreationBody
  ) => Promise<void>;
};

export const useNotifyMeModalStore = create<NotifyMeModalState>()(
  devtools(
    immer((set) => ({
      activeSubmission: undefined,
      activeForm: 'email',
      submitError: null,
      subscribedList: [],
      addToSubscribedList: (key) => {
        set((state) => {
          state.subscribedList.push(key);
        });
      },
      setSubmitError: (hasError) => {
        set((state) => {
          state.submitError = hasError;
        });
      },
      setActiveForm: (form: NotifyMeFormVariant) => {
        set((state) => {
          state.activeForm = form;
        });
      },
      checkEmail: async (email: string) => {
        const { success } = await checkEmailExistence(
          { email: email.toLowerCase() },
          {},
          null
        );
        if (success) {
          set((state) => {
            state.activeForm = 'login';
          });

          return;
        }
        set((state) => {
          state.activeForm = 'guest';
        });
      },
      submitLogin: async (email: string, password: string) => {
        const result = await login(email, password);
        if (result.ok) {
          set((state) => {
            state.activeForm = 'none';
          });
        } else {
          set((state) => {
            state.submitError = true;
          });
        }
      },
    })),
    { name: 'notifyMeModal' }
  )
);
