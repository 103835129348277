import {
  Modal,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalTitle,
} from '@finn/design-system/atoms/modal';
import React, { SyntheticEvent } from 'react';

import RawHtml from '~/components/RawHtml';

import { ContactUsBox } from '../ContactUsBox';

interface ModalData {
  modal_title: string;
  modal_text: string;
}

type Props = {
  open: boolean;
  onClose: (event?: SyntheticEvent) => void;
  data: ModalData;
};

export const PreLovedModal: React.FC<Props> = ({
  open,
  onClose,
  data: { modal_text, modal_title },
}) => {
  return (
    <Modal open={open} onOpenChange={(isOpen) => !isOpen && onClose()}>
      <ModalContent>
        <ModalHeader>
          <ModalTitle>{modal_title}</ModalTitle>
        </ModalHeader>
        <span className="body-16-light">
          <RawHtml html={modal_text} />
        </span>
        <ModalFooter>
          <ContactUsBox />
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};
