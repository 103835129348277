import { PageDataProvider } from '@finn/auto-ui/contexts/PageData';
import CosmicModule from '@finn/auto-ui/ui-modules/CosmicModule';
import { SpacingWrapper } from '@finn/platform-modules';
import React from 'react';

import { CosmicModuleType } from '~/types/cosmicModules';

export function TrustPilot({ pdpModules, reviews }) {
  const trustPilotModule = pdpModules.metadata.modules.find(
    (module) => module.type === CosmicModuleType.WEB_MODULE_TRUSTPILOT
  );

  if (!trustPilotModule) {
    return null;
  }

  return (
    <PageDataProvider reviews={reviews}>
      <SpacingWrapper>
        <CosmicModule data={trustPilotModule} />
      </SpacingWrapper>
    </PageDataProvider>
  );
}
