import { VehicleContext } from '@finn/ua-vehicle';
import { useFormattedPrice } from '@finn/ui-utils';
import { Grid, makeStyles, Theme, useMediaQuery } from '@material-ui/core';
import React, { useContext } from 'react';
import { useIntl } from 'react-intl';

import { useIsPDPCarInfoExp } from '../ProductDetails/utils/useIsPDPCarInfoExp';
import { EmissionDetails } from './EmissionDetails';
import KeyDataListItem, { IListItem } from './KeyDataListItem';

interface IProps {
  items: IListItem[];
  modalView?: boolean;
  configureView?: boolean;
}

export const useStyles = makeStyles((theme: Theme) => ({
  container: {
    marginTop: theme.spacing(2),
    '&.configure': {
      marginTop: 0,
    },
  },
  drawer: {
    '&.MuiGrid-item': {
      paddingTop: theme.spacing(0),
      paddingBottom: theme.spacing(3),
    },
  },
}));

const KeyDataList: React.FunctionComponent<IProps> = ({
  items,
  modalView,
  configureView,
}) => {
  const classes = useStyles();
  const i18n = useIntl();
  const { vehicle } = useContext(VehicleContext);
  const msrp = vehicle?.price?.msrp ?? 0;
  const msrpFormatted = useFormattedPrice(msrp);

  const drawerView = useIsPDPCarInfoExp();

  const isLarge = useMediaQuery((theme: Theme) => theme.breakpoints.up('md'));
  const gridSpacing = configureView ? 1 : isLarge ? 5 : 3;
  const equipmentMSRPLabel = i18n.formatMessage({
    id: 'pdp.equipment.manufacturerPrice',
  });

  const shouldDisplayMSRP = Boolean(equipmentMSRPLabel && msrp);

  if (shouldDisplayMSRP) {
    items.push({
      id: 0,
      label: msrpFormatted,
      title: equipmentMSRPLabel,
    });
  }

  return (
    <>
      <Grid
        container
        item
        sm={12}
        spacing={gridSpacing}
        className={[classes.container, configureView && 'configure'].join(' ')}
      >
        {items
          .filter((item) => !!item.label)
          .map((item) => (
            <Grid
              key={item.id}
              item
              className={drawerView && classes.drawer}
              xs={12}
              sm={modalView || configureView || drawerView ? 12 : 6}
              md={configureView || drawerView ? 12 : modalView ? 6 : 4}
              lg={configureView || drawerView ? 12 : modalView ? 4 : 3}
            >
              <KeyDataListItem {...item} configureView={configureView} />
            </Grid>
          ))}

        <Grid
          item
          xs={12}
          className={drawerView && classes.drawer}
          sm={modalView || configureView || drawerView ? 12 : 6}
          md={configureView || drawerView ? 12 : modalView ? 6 : 4}
          lg={configureView || drawerView ? 12 : modalView ? 4 : 3}
        >
          <EmissionDetails configureView={configureView} />
        </Grid>
      </Grid>
    </>
  );
};

export default KeyDataList;
