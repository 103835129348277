import { ReactNode } from 'react';

type Props = {
  renderMetaContent?: () => ReactNode;
};

export const MetaContent = ({ renderMetaContent }: Props) => (
  <>{renderMetaContent ? renderMetaContent() : null}</>
);

export default MetaContent;
