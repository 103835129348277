import { SkeletonNode } from '@finn/atoms/SkeletonNode';
import { Features, useIsABVariant, useIsCVariant } from '@finn/ua-featureflags';
import { VehicleContext } from '@finn/ua-vehicle';
import {
  getForBusinessCookie,
  TrackingEventName,
  useCalculatePrice,
  usePreDiscount,
  useTrackingStore,
} from '@finn/ui-modules';
import { getFormattedPrice } from '@finn/ui-utils';
import React, { useContext, useEffect } from 'react';
import { useIntl } from 'react-intl';

import { usePartnerDiscount } from '~/hooks/usePartnerDiscount';

import { useConfigureStore } from '../../ConfigurePage';

const SubscriptionPrice: React.FC<{ className?: string }> = ({ className }) => {
  const i18n = useIntl();
  const isForBusiness = getForBusinessCookie();
  const { vehicle } = useContext(VehicleContext);
  const { kilometerPackage, term, downPayment, downPaymentAmount } =
    useConfigureStore();
  const partnerDiscount = usePartnerDiscount();
  const isBVariant = useIsABVariant(Features.ExpDownPaymentOptions);
  const isCVariant = useIsCVariant(Features.ExpDownPaymentOptions);
  const track = useTrackingStore((state) => state.track);

  useEffect(() => {
    if (partnerDiscount) {
      track(TrackingEventName.PDP_PARTNER_DISCOUNT_SHOWED, {
        additionalProps: { partnerDiscount },
      });
    }
  }, [partnerDiscount, track]);

  const isExperimentDownPayment = isBVariant || isCVariant;

  const isDownPayment = downPayment;

  const isFakedoorDownpayment =
    useIsABVariant(Features.ExpFakedoorDownpayment) && term !== 1;
  const params = {
    term,
    vehicle,
    isDownPayment,
    kilometerPackage,
    downPaymentAmount,
    isFakedoorDownpayment,
    isExperimentDownPayment,
  };
  let totalPrice = useCalculatePrice(params);

  let preDiscountPrice = usePreDiscount({
    vehicle,
    price: totalPrice,
    term,
    isForBusiness,
    isDownPayment,
  });

  if (partnerDiscount > 0) {
    if (!preDiscountPrice) {
      preDiscountPrice = getFormattedPrice(i18n, totalPrice, 0);
    }
    totalPrice = totalPrice - totalPrice * partnerDiscount;
  }

  const monthlyPriceString = getFormattedPrice(i18n, totalPrice, 0);

  const title = i18n.formatMessage({
    id: `pdp.price.${isForBusiness ? 'b2b' : 'b2c'}_vat`,
  });

  return (
    <div className={className}>
      <div className="flex">
        <span className="mobile-t3-semibold sm:web-t3-semibold inline-block">
          {i18n.formatMessage({ id: 'pdp.price.starting_from' })}&nbsp;
        </span>

        {preDiscountPrice && (
          <SkeletonNode loading={false}>
            <span className="mobile-t3-semibold sm:web-t3-semibold !text-iron mr-2 inline-block line-through">
              {preDiscountPrice}
            </span>
          </SkeletonNode>
        )}

        <SkeletonNode loading={false}>
          <span className="mobile-t3-semibold sm:web-t3-semibold inline-block">
            {monthlyPriceString}
          </span>
        </SkeletonNode>
      </div>
      <div className="body-14-light flex flex-col pb-4 pt-2">
        <span>{title}</span>
        <span>
          {downPayment &&
            i18n.formatMessage({ id: 'pdp.downPayment.priceText' })}
        </span>
      </div>
    </div>
  );
};

export default SubscriptionPrice;
