import { Image } from '@finn/ui-components';
import once from 'lodash/once';
import React, { useCallback, useMemo, useRef, useState } from 'react';
import QuickPinchZoom, { make3dTransformValue } from 'react-quick-pinch-zoom';

import PDPTracking from '~/components/ProductDetails/utils/PDPTracking';

import { REFERENCE_IMAGE_HEIGHT, REFERENCE_IMAGE_WIDTH } from './constants';

type Props = {
  url: string;
  srcSet: string;
  className: string;
  fullScreen: boolean;
  altText?: string;
  isFirst?: boolean;
};

const PinchZoomSlide: React.FC<Props> = ({
  url,
  srcSet,
  className,
  fullScreen,
  altText,
  isFirst,
}) => {
  const imgRef = useRef<HTMLImageElement | null>(null);
  const quickPinchZoomRef = useRef(null);
  const [currentScale, setCurrentScale] = useState(1);

  // avoid sending multiple event
  const trackFirstZoomEvent = useMemo(() => {
    return once(PDPTracking.imageZoomed);
  }, []);

  const onUpdate = useCallback(
    ({ x, y, scale }) => {
      const theImage = imgRef?.current;
      if (theImage) {
        if (scale !== currentScale) {
          setCurrentScale(scale);
        }
        const value = make3dTransformValue({ x, y, scale });
        theImage.style.setProperty('transform', value);
      }
    },
    [currentScale, imgRef]
  );

  const onDoubleTap = useCallback(() => {
    const theImage = imgRef?.current;
    const { width, height } = theImage || { width: 0, height: 0 };
    const midX = width / 2;
    const midY = height / 2;
    if (currentScale > 1) {
      quickPinchZoomRef?.current?.alignCenter?.({
        x: midX,
        y: midY,
        scale: 1,
      });
    } else {
      quickPinchZoomRef?.current?.alignCenter?.({
        x: midX,
        y: midY,
        scale: 2,
      });
    }
  }, [currentScale, imgRef, quickPinchZoomRef]);

  return fullScreen ? (
    <QuickPinchZoom
      onUpdate={onUpdate}
      draggableUnZoomed={false}
      onZoomUpdate={trackFirstZoomEvent}
      shouldInterceptWheel={() => false}
      ref={quickPinchZoomRef}
      onDoubleTap={onDoubleTap}
      animationDuration={250}
      inertia={true}
      inertiaFriction={0.8}
      tapZoomFactor={currentScale > 1 ? -(currentScale - 1) : 1}
    >
      <Image
        ref={imgRef}
        src={url}
        srcSet={srcSet}
        alt={altText}
        className={className}
        width={REFERENCE_IMAGE_WIDTH}
        height={REFERENCE_IMAGE_HEIGHT}
      />
    </QuickPinchZoom>
  ) : (
    <Image
      ref={imgRef}
      src={url}
      loading={isFirst ? 'eager' : 'lazy'}
      alt={altText}
      className={className}
      width={REFERENCE_IMAGE_WIDTH}
      height={REFERENCE_IMAGE_HEIGHT}
    />
  );
};
export default PinchZoomSlide;
