import { makeStyles, Theme } from '@material-ui/core';

export const useStyles = makeStyles((theme: Theme) => ({
  root: {
    marginTop: theme.spacing(3),
  },
  section: {
    display: 'flex',
    flexWrap: 'wrap',
    margin: 'auto',
  },
  navbar: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  continueButton: {
    [theme.breakpoints.down('xs')]: {
      padding: theme.spacing(2),
    },
  },
  productSide: {
    flexGrow: 0,
    maxWidth: '100%',
    flexBasis: '100%',
    [theme.breakpoints.up('md')]: {
      maxWidth: `${(100 * 2) / 3}%`,
      flexBasis: `${(100 * 2) / 3}%`,
      paddingRight: theme.spacing(5),
    },
  },
  slideWrapper: {
    position: 'relative',
    marginLeft: 0,
  },
  stickyBox: {
    position: 'sticky',
    top: theme.spacing(1),
  },
  productInfo: {
    position: 'relative',
    flexGrow: 1,
    maxWidth: '100%',
    paddingTop: theme.spacing(3),
    margin: 'auto',
    [theme.breakpoints.up('sm')]: {
      flexBasis: '100%',
      maxWidth: '100%',
    },
    [theme.breakpoints.up('md')]: {
      maxWidth: `${100 / 3}%`,
      flexBasis: `${100 / 3}%`,
      paddingTop: 0,
      margin: 0,
    },
  },
  promo: {
    marginTop: 0,
    marginBottom: theme.spacing(2),
    [theme.breakpoints.up('sm')]: {
      minHeight: '10px',
      marginBottom: theme.spacing(2),
    },
    [theme.breakpoints.up('lg')]: {
      minHeight: '10px',
    },
  },
  divider: {
    margin: theme.spacing(2, 0),
    height: 0,
  },
  dividerPearl: {
    margin: theme.spacing(3, 0),
    [theme.breakpoints.up('sm')]: {
      margin: theme.spacing(5, 0),
    },
  },
  mb1: {
    marginBottom: theme.spacing(1),
  },
  mb2: {
    marginBottom: theme.spacing(2),
  },
  availabilityWrapper: {},
  leftInStockWrapper: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(0.4),
  },
  trustWidget: {
    marginBottom: theme.spacing(2),
    [theme.breakpoints.up('sm')]: {
      left: -40, // Ugly hack to have it left alligned
    },
  },
}));
