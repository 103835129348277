import { Button } from '@finn/design-system/atoms/button';
import { interactionTrackingEvent, TrackingEventName } from '@finn/ua-tracking';
import { cn } from '@finn/ui-utils';
import { makeStyles, Theme } from '@material-ui/core';
import React, { useCallback } from 'react';
import { useIntl } from 'react-intl';

const useStyles = makeStyles((theme: Theme) => ({
  button: {
    marginTop: theme.spacing(5),
  },
}));
interface IProps {
  onClick: () => void;
  isLoading?: boolean;
  testId?: string;
  isInPDP?: boolean;
}
export const ContinueButton: React.FunctionComponent<IProps> = ({
  onClick,
  isLoading,
  testId,
  isInPDP,
}) => {
  const i18n = useIntl();
  const classes = useStyles();

  const handleClick = useCallback(() => {
    interactionTrackingEvent(TrackingEventName.CTA_CLICKED, {
      location: 'configure',
    });

    onClick();
  }, [onClick]);

  const title = i18n.formatMessage({
    id: 'pdp.continueToBooking',
  });

  return (
    <>
      <Button
        loading={isLoading}
        testId={testId || 'submit'}
        className={cn(!isInPDP && classes.button, 'w-full')}
        disabled={isLoading}
        onClick={handleClick}
      >
        {title}
      </Button>
    </>
  );
};
